html {
    background-color: #ffffff;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-family: 'Lato';
}

.body-wrapper {
    overflow-x: hidden;
}


/* Banner */
.banner {
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}

.banner .logo-box {
    background: #EAF2F8;
    border-radius: 10px;
}

.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}

.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}

.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
}

.banner .info:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}

.banner .domaner-btn {
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}

.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}

@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }

    .banner .info {
        font-size: 18px;
    }

    .banner .domaner-btn {
        font-size: 18px;
    }

    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}

@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }

    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }

    .banner .title {
        line-height: 24px;
        text-align: center;
    }

    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }

    .banner .info:before {
        content: none;
    }
}

@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-header .container.domainer::before {
    content: none;
}



*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.site-logo {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    position: relative;
    padding-left: 40px;
}

.site-logo::before {
    position: absolute;
    content: "";
    width: 31.78px;
    height: 34px;
    background-image: url(../image/logo-blockchaincrowdfund.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top: -5px;
    left: 0;
}

.site-logo span {
    font-weight: 900;
}

.logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 55px;
}

.logo-wrapper ul {
    display: flex;
    align-items: center;
    gap: 90px;
}

.logo-wrapper ul li a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(6, 6, 6, 0.74);
    transition: all 0.2s ease-in-out;
}

.logo-wrapper ul li a:hover {
    color: #73DFFF;
}


h1 {
    font-weight: 300;
    font-size: 48px;
    line-height: 59px;
    color: #060606;
    padding-bottom: 40px;
}

h1 span {
    font-weight: 900;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 55px;
    color: #060606;
    padding-bottom: 30px;
}

h3 {
    font-weight: 900;
    font-size: 30px;
    line-height: 60px;
    color: #060606;
    padding-bottom: 25px;
}

p {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #383838;
}

p:not(:last-of-type) {
    padding-bottom: 25px;
}

.site-header {
    background-color: #F1D2D4;
    padding-bottom: 80px;
    overflow: hidden;
}

.site-header-inner {
    margin-left: 40%;
    padding-top: 70px;
    padding-left: 60px;
    padding-bottom: 90px;
    background-color: #F1D2D4;
}

.site-header-inner p {
    color: #1F1F1F;
}

.site-header .container {
    position: relative;
    z-index: 2;
}

.site-header .container::before {
    position: absolute;
    content: "";
    background-image: url(../image/hero-img-min.webp);
    width: 1333px;
    height: 710px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    right: 300px;
}

.what-is {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 60px 0;
    position: relative;
    z-index: 2;
}

.what-is-img {
    position: relative;
}

.what-is-img::before {
    position: absolute;
    content: "";
    width: 741px;
    height: 419px;
    top: 70px;
    right: 0;
    background-image: url(../image/what-is-img.webp);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
}

.what-is-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.grid-box h2 span {
    display: block;
}

.grid-box {
    background: #FFFFFF;
    box-shadow: 0px -79px 92px #F6F2F1;
    border-radius: 2px;
    padding: 120px 38px 90px;
    position: relative;
    box-sizing: border-box;
}

.grid-box:first-of-type::before {
    position: absolute;
    content: "";
    background-image: url(../image/crowdfunding.svg);
    width: 54px;
    height: 54px;
    top: 50px;
    left: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}

.grid-box:nth-of-type(2)::before {
    position: absolute;
    content: "";
    background-image: url(../image/blockchain.svg);
    width: 48px;
    height: 48px;
    top: 50px;
    left: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}

.beige-wrapper {
    background-color: #FAF7F7;
    padding: 85px 0;
    text-align: center;
}

.wallets {
    display: flex;
    align-items: center;
    gap: 35px;
    padding-top: 45px;
    padding-bottom: 40px;
}

.wallet {
    width: 100%;
    height: 208px;
    background: #FFFFFF;
    box-shadow: 0px 28px 92px #F0E6E6;
    border-radius: 2px;
    padding: 85px 10px;
    position: relative;
}

.desktop::before {
    position: absolute;
    width: 44px;
    height: 44px;
    content: "";
    background-image: url(../image/monitor.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 28px;
    left: calc(50% - 17.5px);
}

.hardware::before {
    position: absolute;
    width: 44px;
    height: 33px;
    content: "";
    background-image: url(../image/hardware-wallet.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 35px;
    left: calc(50% - 22px);
}

.web::before {
    position: absolute;
    width: 38px;
    height: 38px;
    content: "";
    background-image: url(../image/internet.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 35px;
    left: calc(50% - 19px);
}

.mobile::before {
    position: absolute;
    width: 38px;
    height: 38px;
    content: "";
    background-image: url(../image/smartphone.svg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 35px;
    left: calc(50% - 19px);
}

.wallet h3 {
    font-weight: 900;
    font-size: 20px;
    padding-bottom: 10px;
    color: #060606;
}

.wallet p {
    font-size: 16px;
    color: #414141;
    line-height: 24px;
}

.what-you-need-wrapper {
    background-color: #ffffff;
    box-shadow: 0px 28px 92px #F0E6E6;
    border-radius: 2px;
    padding: 50px 70px 50px 130px;
    margin-top: 40px;
}

.what-you-need-wrapper p {
    text-align: left;
    position: relative;
}

.what-you-need-wrapper p::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background-color: #DFF7FF;
    font-weight: 900;
    font-size: 36px;
    line-height: 60px;
    color: #060606;
    padding-left: 24px;

    top: -10px;
    left: -70px;
}

.what-you-need-wrapper p:first-of-type:before {
    content: "1.";
}

.what-you-need-wrapper p:nth-of-type(2)::before {
    content: "2.";
}

.what-you-need-wrapper p:nth-of-type(3)::before {
    content: "3.";
}

.what-you-need-wrapper p a {
    color: #1CCAFE;
    text-decoration: underline;
}

.charities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 90px 0;
}

.charities-content {
    display: flex;
    align-items: center;
}

.charities-content h2 span {
    display: block;
}

.charities-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}

.charities-links li {
    padding-bottom: 23px;
}

.charities-links li a {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #383838;
    position: relative;
    transition: all 0.25s ease-in-out;
}

.charities-links li a::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background-color: #DFF7FF;
    border-radius: 2px;
    left: -20px;
    z-index: -1;
}

.charities-links li a::after {
    position: absolute;
    content: "";
    background-image: url(../image/arrow-right.svg);
    width: 20px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    right: -25px;
    top: 7px;
    transform: translate(-11px, 0px);
    opacity: 0;
    transition: all 0.25s ease-in-out;
}

.charities-links li a:hover::after {
    opacity: 1;
    transform: translate(0, 0);
}

.charities-links li a:hover {
    color: #29CDFE;
    text-decoration: underline;
}

.future {
    display: grid;
    grid-template-columns: 1fr 52%;
    padding-bottom: 100px;
}

.future-img {
    position: relative;
}

.future-img::before {
    position: absolute;
    content: "";
    background-image: url(../image/charity.webp);
    background-size: contain;
    background-repeat: no-repeat;
    width: 551px;
    height: 496px;
    left: -50px;
    top: -20px;
}

.about-us {
    padding: 80px 90px;
    background: #F1D2D4;
    border-radius: 2px;
    text-align: center;
}

.contact-cta {
    background: #FFFFFF;
    border-radius: 2px;
    color: #29CDFE;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    padding: 25px 55px;
    display: inline-block;
    margin-top: 29px;
    position: relative;
    transition: all 0.2s ease-in-out;
}

footer {
    display: none;
}


@media only screen and (max-width: 1200px) {
    .future-img::before {
        left: -90px;
        z-index: -1;
    }

    .what-is-img::before {
        top: 164px;
        right: -100px;
    }

    .site-header-inner,
    .site-header {
        padding-bottom: 60px;
    }

    .site-header .container::before {
        right: 125px
    }

    .logo-wrapper ul {
        gap: 80px;
    }
}

@media only screen and (min-width: 991px) {
    .wallets {
        display: initial !important;
    }

    .wallet {
        height: 85px !important;
    }

    .swiper-slide {
        flex-shrink: 1 !important;
        margin-right: 0 !important;
    }

    .swiper-wrapper {
        gap: 25px !important;
        padding-top: 44px;
        padding-bottom: 45px;
        height: auto !important;
    }

    .swiper-wrapper {
        pointer-events: none;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-autoheight, .swiper-autoheight .swiper-slide {
        overflow: visible;
    }

    .what-is-grid {
        display: initial;
    }

}

@media only screen and (max-width: 992px) {
    .logo-wrapper ul {
        display: none;
    }

    .site-header-inner {
        background: none;
        position: relative;
        margin-left: 0;
    }

    .site-header-inner::before {
        position: absolute;
        content: "";
        width: 200%;
        height: 100%;
        background: rgba(241, 210, 212, 0.8);
        backdrop-filter: blur(8px);
        z-index: -1;
        top: 0;
        left: 0;
    }

    .site-header .container::before {
        right: -150px;
    }

    .what-is-img::before {
        content: none;
    }

    .what-is {
        grid-template-columns: 1fr;
    }

    .mobile-img-what-is {
        height: 250px;
        width: 100%;
        position: relative;
    }

    .mobile-img-what-is::before {
        position: absolute;
        content: "";
        background-image: url(../image/what-is-img.webp);
        width: 100%;
        height: 100%;
        left: -50px;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .what-is-grid {
        display: block;
        padding-bottom: 61px;
    }

    .swiper-autoheight, .swiper-autoheight .swiper-slide {
        overflow: visible;
    }

    .swiper-pagination-bullet {
        border-radius: 0;
        background: #67D9FB;
    }

    .swiper-slide {
        box-sizing: border-box;
    }

    .wallets .swiper-slide {
        height: 200px !important;

    }

    .site-header {
        background-color: transparent;
    }

    .charities {
        grid-template-columns: 1fr;
    }

    .future-img::before {
        left: -176px;
        top: 52px;
    }



}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 36px;
        line-height: 44px;
    }

    h2 {
        font-size: 30px;
        line-height: 36px;
    }

    h3 {
        font-size: 30px;
        line-height: 36px;

    }

    .container {
        padding: 0 22px;
    }

    .site-header {
        padding-bottom: 0;
    }

    .what-you-need-wrapper {
        padding: 50px 70px 50px 104px;
    }

    .charities {
        padding: 50px 0;
    }

    .charities-links {
        grid-template-columns: 1fr;
        padding-left: 20px;
        gap: 0;
    }

    .about-us {
        padding: 50px 45px
    }

    .what-you-need-wrapper {
        padding: 50px 70px 50px 85px;
    }

    .what-you-need-wrapper p::before {
        width: 42px;
        height: 42px;
        font-size: 32px;
        line-height: 45px;
        left: -55px;
    }

    .what-you-need-wrapper h3 {
        text-align: left;
    }

    .future {
        grid-template-columns: 1fr;
        padding-bottom: 50px;
    }

    .future-img-mobile {
        height: 250px;
        position: relative;
    }

    .future-img-mobile::before {
        position: absolute;
        content: "";
        background-image: url(../image/charity.webp);
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        top: 0;
        left: -25px;
    }

    .future-img::before {
        content: none;
    }

    .site-header .container::before {
        width: 888px;
        height: 473px;
    }

    .site-header-inner {
        padding: 40px;
    }
}

@media only screen and (max-width: 376px) {
    .what-you-need-wrapper {
        padding: 50px 31px 50px 67px;
    }

    .about-us {
        padding: 33px 35px;
    }

    .site-header-inner {
        padding: 27px;
    }

    .grid-box {
        padding: 85px 27px 90px;
    }

    .grid-box:first-of-type::before {
        top: 17px;
    }

    .grid-box:nth-of-type(2)::before {
        top: 24px;
    }
}


@media only screen and (max-width: 768px) {
    .what-is-grid .swiper-slide {
        height: auto;
        padding: 120px 38px 50px;
    }

    .swiper {
        overflow: visible;
    }
}

